import type {
  Item,
  ItemTpa,
  Menu,
  MenuTpa,
  Section,
  SectionTpa,
  Label,
  LabelTpa,
  PopulatedMenu,
  PopulatedSection,
} from '../../types/menusTypes';
import type { ItemModifierGroupTpa, ItemModifierGroup } from '../../types/modifiers';
import type {
  ItemPriceVariant,
  ItemPriceVariantTpa,
  PopulatedItemPriceVariantTpa,
} from '../../types/priceVariants';
import type { MenusAvailabilityStatus, Money } from '../../types/businessTypes';
import type { PopulatedItemTpa } from 'root/types/item';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import {
  AvailabilityStatus,
  type MenuAvailabilityStatus,
} from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';

export const getItemLabels = ({ item, labelsList }: { item: ItemTpa; labelsList: Label[] }) => {
  return labelsList?.filter((label) => item?.labels?.find((l) => l.id === label.id)) || [];
};

export const convertItemModifierGroups = (
  modifierGroups: ItemModifierGroupTpa[]
): ItemModifierGroup[] => {
  return modifierGroups.map((modifierGroup) => {
    return {
      id: modifierGroup.id ?? '',
    };
  });
};

export const convertPriceVariantsToPriceVariantType = (
  priceVariants: ItemPriceVariantTpa[]
): ItemPriceVariant[] => {
  return priceVariants.map((priceVariant) => {
    return {
      id: priceVariant.variantId,
      price: priceVariant.price,
    } as ItemPriceVariant;
  });
};

export const convertPopulatedPriceVariantsToPriceVariantType = (
  priceVariants: PopulatedItemPriceVariantTpa[]
): ItemPriceVariant[] => {
  return priceVariants.map((priceVariant) => {
    return {
      id: priceVariant.variantId,
      price: priceVariant.price,
    } as ItemPriceVariant;
  });
};

export const convertPopulatedItemsToItemType = ({
  items,
  currency,
  imagesData = [],
}: {
  items: PopulatedItemTpa[];
  currency: string;
  imagesData?: { id: string; width: number; height: number }[];
}): Item[] => {
  const updatedItems: Item[] = [];
  items.forEach((item) => {
    const price: Money = {
      amount: Number(item.price) || 0,
      currency,
    };
    const priceVariants =
      item?.priceVariants?.variants &&
      convertPopulatedPriceVariantsToPriceVariantType(item?.priceVariants?.variants);
    const modifierGroups = item?.modifierGroups && convertItemModifierGroups(item?.modifierGroups);

    if (item.image && item.image.id && !(item.image.width || item.image.height)) {
      const imgData = imagesData.find((img) => img.id === item.image?.id);

      item.image.width = item.image.width || imgData?.width;
      item.image.height = item.image.height || imgData?.height;
    }

    if (item.id) {
      updatedItems.push({
        id: item.id,
        name: item.name,
        description: item.description,
        price,
        priceVariants,
        labels: convertLabelsToLabelType({ labelsList: item.labels ?? [] }),
        image: item.image,
        additionalImages: item.additionalImages,
        visible: !!item.visible,
        orderSettings: {
          inStock: item.orderSettings?.inStock ?? true,
          taxGroupId: item.orderSettings?.taxGroupId,
          acceptSpecialRequests: item.orderSettings?.acceptSpecialRequests,
        },
        modifierGroups,
        revision: item.revision || '0',
      });
    }
  });

  return updatedItems;
};

export const convertLabelsToLabelType = ({ labelsList }: { labelsList: LabelTpa[] }): Label[] => {
  const updatedLabels: Label[] = [];
  labelsList.forEach((label) => {
    if (label.id) {
      updatedLabels.push({
        id: label.id,
        name: label.name,
        icon: label.icon,
      });
    }
  });

  return updatedLabels;
};

export const convertSectionsToSectionType = ({
  sections,
}: {
  sections: SectionTpa[];
}): Section[] => {
  const updatedSections: Section[] = [];
  sections.forEach((section) => {
    if (section.id) {
      updatedSections.push({
        id: section.id,
        name: section.name,
        description: section.description,
        image: section.image,
        additionalImages: section.additionalImages,
        visible: section.visible !== false,
        itemIds: section.itemIds || [],
        revision: section.revision || '0',
      });
    }
  });

  return updatedSections;
};

export const convertMenusToMenuType = ({ menus = [] }: { menus?: MenuTpa[] }): Menu[] => {
  const updatedMenus: Menu[] = [];
  menus.forEach((menu) => {
    if (menu.id) {
      updatedMenus.push({
        id: menu.id,
        name: menu.name,
        description: menu.description,
        visible: !!menu.visible,
        sectionIds: menu.sectionIds || [],
        revision: menu.revision || '0',
      });
    }
  });

  return updatedMenus;
};

export const convertDataTypes = async ({
  currency,
  menusTpa = [],
  sectionsTpa = [],
  itemsTpa = [],
  labelsTpa = [],
  imagesData,
}: {
  currency: string;
  menusTpa?: MenuTpa[];
  sectionsTpa?: SectionTpa[];
  itemsTpa?: ItemTpa[] | PopulatedItemTpa[];
  labelsTpa?: LabelTpa[];
  imagesData?: { id: string; width: number; height: number }[];
}) => {
  const menus = convertMenusToMenuType({
    menus: menusTpa,
  });

  const sections = convertSectionsToSectionType({
    sections: sectionsTpa,
  });

  const items = convertPopulatedItemsToItemType({
    items: itemsTpa as PopulatedItemTpa[],
    currency,
    imagesData,
  });

  return { menus, sections, items };
};

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  items: Item[],
  sentry?: ErrorMonitor
): PopulatedMenu => {
  const itemMap = new Map();
  const sectionMap = new Map();

  items.forEach((item) => itemMap.set(item.id, item));
  sections.forEach((section) => sectionMap.set(section.id, section));

  const menuSections =
    menu.sectionIds
      ?.map((sectionId) => sectionMap.get(sectionId))
      ?.filter((section) => !!section) ?? [];

  if ((menu.sectionIds?.length ?? 0) !== menuSections.length) {
    const invalidSectionIds = menu.sectionIds?.filter((sectionId) => !sectionMap.has(sectionId));
    sentry?.captureMessage('Menu has invalid sectionIds', {
      contexts: {
        menu: {
          ...menu,
          invalidSectionIds,
        },
      },
    });
    console.error(`Menu ${menu.id} has invalid sectionIds: ${invalidSectionIds}`, menu);
  }

  const visibleMenuSections = menuSections.filter((section) => section.visible);

  const invalidItemIds: string[] = [];

  const sectionsWithItems = visibleMenuSections.map((section) => {
    const sectionItems = section.itemIds
      ?.map((itemId: string) => itemMap.get(itemId))
      .filter((item: Item) => !!item);

    if ((section.itemIds?.length ?? 0) !== sectionItems.length) {
      invalidItemIds.push(
        ...(section.itemIds?.filter((itemId: string) => !itemMap.has(itemId)) ?? [])
      );
    }

    return {
      ...section,
      size: sectionItems.length,
      items: sectionItems,
    };
  }) as PopulatedSection[];

  if (invalidItemIds.length) {
    sentry?.captureMessage('Menu has invalid itemIds', {
      contexts: {
        menu: {
          ...menu,
          invalidItemIds,
        },
      },
    });
    console.error(`Menu ${menu.id} has invalid itemIds: ${invalidItemIds}`, sectionsWithItems);
  }

  return {
    ...menu,
    sections: sectionsWithItems,
    size: items.length,
  };
};

export const convertDate = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
};

export const createTimeSlotId = (startTime: Date, endTime: Date) =>
  `${startTime?.toUTCString()}-${endTime?.toUTCString()}`;

export const convertMenuAvailabilityStatusArrayToKeyValue = (
  menuStatuses: MenuAvailabilityStatus[] = []
): MenusAvailabilityStatus => {
  return menuStatuses.reduce((acc, status) => {
    if (status.menuId) {
      acc[status.menuId] = status.availabilityStatus ?? AvailabilityStatus.AVAILABLE;
    }
    return acc;
  }, {} as MenusAvailabilityStatus);
};
