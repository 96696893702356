import model from './model';
import { DishesController, getDishesState } from './dishesController';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { hasDataChanged } from '../../utils/utils';
import type { DishesWidgetProps } from '../../types/menusTypes';
import type { Item } from 'root/types/menusTypes';
import { runInAction, autorun } from 'mobx';

export default model.createController(({ $w, $bind, $widget, flowAPI, initState }) => {
  const { controllerConfig, translations, environment, formatCurrency, experiments } = flowAPI;
  const t = translations.t as TFunction;
  const { isMobile, isEditor } = environment;
  const { wixCodeApi } = controllerConfig;
  const isMemberLoggedIn = !!wixCodeApi.user.currentUser.loggedIn;

  const dishesController = new DishesController(
    $bind,
    $w,
    t,
    formatCurrency,
    isMobile,
    experiments,
    isMemberLoggedIn
  );

  let prevItems: Item[] = [];

  const { state } = initState({
    dishesWidget: getDishesState($widget.props.data.items, $widget.props.data.isTruncated),
  });

  $widget.onPropsChanged((prevProps, nextProps) => {
    const { items, menuId, sectionId, isMenuAvailable, isTruncated }: DishesWidgetProps =
      nextProps.data;

    const isMenuAvailabilityChanged = isMenuAvailable !== prevProps.data.isMenuAvailable;
    const shouldInit =
      isMenuAvailabilityChanged ||
      hasDataChanged({
        prevData: prevItems,
        nextData: items,
      });

    const dishState = getDishesState(items, isTruncated);

    if (shouldInit) {
      prevItems = items;
      dishesController.init(items, isEditor, menuId, sectionId, isMenuAvailable);
    }

    if (dishState !== state.dishesWidget) {
      runInAction(() => {
        state.dishesWidget = dishState;
      });
    }
  });

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const { items, menuId, sectionId, isMenuAvailable }: DishesWidgetProps = $widget.props.data;
      if (isEditor && items.length > 0) {
        dishesController.deleteItemCounterInEditor(menuId);
      }
      const shouldInit = hasDataChanged({
        prevData: prevItems,
        nextData: items,
      });
      autorun(() => {
        dishesController.switchState(state.dishesWidget);
      });
      if (shouldInit) {
        prevItems = items;
        dishesController.init(items, isEditor, menuId, sectionId, isMenuAvailable);
      }
    },
    exports: {},
  };
});
